import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HOME } from "../../../src/ApplicationRoutes/RoutesPath";

import { CircularProgress, Container } from "@mui/material";

import { verifyToken } from "../../services/api";
import ErrorPage from "../ErrorPage/ErrorPage";



const AuthCallback = ({ isLoggedIn, isLoading, hasError , refresh}) => {

    const navigate = useNavigate();
    const [error, setError] = useState({});
    
    useEffect(() => {
        if (isLoggedIn && !isLoading) {
            let path = localStorage.getItem("current_path")?"/"+localStorage.getItem("current_path"):HOME;

            verifyToken().then((res) => {
                if (res.status === 200) {
                    setError({});
                    navigate(path);
                }
            }).catch((err) => {
                setError(err);
            });
        }
        if (hasError && !isLoading) {
            console.log("Error Occured");
        }
    }, [isLoggedIn, hasError, isLoading, navigate]);

    if(error?.status)
    {
         return <ErrorPage statusCode={500} statusMessage={"Failed in establishing the connection to the server"} />
    }
    return (
        <div>
            <Container maxWidth="md" margin="normal" className="homepage-container">
                <div
                    style={{
                        marginTop: "1rem",
                        width: "100vw",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <CircularProgress color="inherit" />
                </div>
            </Container>
        </div>
    )
}


export default AuthCallback;