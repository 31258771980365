import { makeStyles } from "@mui/styles";
import Checkbox from "@mui/material/Checkbox";
import { Grid2 as Grid, Typography } from "@mui/material";

import useCustomSearchParams from "../../../hooks/useCustomSearchParams";

const useStyles = makeStyles((theme) => ({
  label: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0.30rem 0.6rem 0.30rem 0.6rem",
    fontSize: "1em",
    fontWeight: 600,
    color: "#000",
  },
}));

const ValidateSkills = () => {
  const classes = useStyles();

  const { validatedSkills, setValidatedSkills } = useCustomSearchParams();

  const handleChange = (e) => setValidatedSkills(e.target.checked);

  return (
    <Grid container size={{sm:9, md:6, lg:6, xl:6}} marginTop={"1.2rem"}>
      <Typography className={classes.label}>Look under Validated Skills?</Typography>
      <Checkbox size="medium" checked={validatedSkills} onChange={handleChange} />
    </Grid>
  );
};

export default ValidateSkills;
