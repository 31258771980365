import { makeStyles } from "@mui/styles";
import { Autocomplete, Typography, Grid2 as Grid } from "@mui/material";
import TextField from "@mui/material/TextField";

import useCustomSearchParams from "../../../hooks/useCustomSearchParams";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5em",
    marginBottom: "1.5rem",
    height: "2.3em",
    marginTop: "1rem",
  },
  label: {
    background: "#FCD839",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "5rem",
    height:"2.2rem",
    padding: "0.40rem 0.55rem 0.40rem 0.55rem",
    marginRight: "0.5em",
    fontSize: "0.8em",
    fontWeight: 600,
    color: "#000",
    fontFamily: 'Shell Fonts, sans-serif',
  },

  roleDropdown: {
    fontWeight: 500,
    borderColor: theme.palette.divider, // Add this line to use the theme's divider color
  },
}));

const RoleComponent = () => {
  const classes = useStyles();

  const { role, setRole } = useCustomSearchParams();
  const { resourceRoleOptions } = useCustomSearchParams();
  
  const displayRoleList = resourceRoleOptions["Role"]?.sort((a, b) => a.localeCompare(b));

  const handleRoleChange = (e, value) => {
    if (value) setRole(value);
    else setRole("Any");
  };

  return (
<Grid className={classes.container} size={{xs:12, sm:12, md:12, lg:12, xl:12}}>
      <Typography className={classes.label}>Resource Role</Typography>
      <Autocomplete
        size="small"
        value={role}
        className={classes.roleDropdown}
        options={displayRoleList}
        renderInput={(params) => (
          <TextField {...params}/>
        )}
        sx={{
          width:500,
        }}
        onChange={handleRoleChange}
      />
    </Grid>
  );
};

export default RoleComponent;
