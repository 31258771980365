import React from "react";
import { Container, Grid2 as Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import RPLN_Logo from "../../asset/rplnPage.png";
import Skills_Logo from "../../asset/skillSearch.png";
import { RPLN, SKILLS } from "../../ApplicationRoutes/RoutesPath";
import { CARD_DESCRIPTION } from "../../const";

import "./HomePage.css";
import LandingDisplayCard from "../../component/LandingDisplayCard/LandingDisplayCard";


export default function HomePage() {
  const navigate = useNavigate();
  
  return (
    <div>
      <Container maxWidth="md" margin="normal" className="homepage-container">
        <Typography variant="body3" style={{ textAlign: "center" }} mb={2}>
          Choose the search type
        </Typography>
        <Grid  container spacing={2}>
          <Grid size={{xs:12, sm:12, md:5, lg:5,xl:5}}>
              <div onClick={() => navigate(RPLN)}>
                <LandingDisplayCard
                  image={RPLN_Logo}
                  title={CARD_DESCRIPTION[0].title}
                  description={CARD_DESCRIPTION[0].description}
                  cardFor={CARD_DESCRIPTION[0].cardFor}
                  addMsg={CARD_DESCRIPTION[0].addMsg}
                />
              </div>
          </Grid>
          <Grid size={{xs:12, sm:12, md:5, lg:5,xl:5}} >
              <div onClick={() => navigate(SKILLS)}>
                <LandingDisplayCard
                  image={Skills_Logo}
                  title={CARD_DESCRIPTION[1].title}
                  description={CARD_DESCRIPTION[1].description}
                  cardFor={CARD_DESCRIPTION[1].cardFor}
                  addMsg={CARD_DESCRIPTION[1].addMsg}
                />
              </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
