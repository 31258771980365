import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import logoImage from "../../asset/logo.png";

import { Link } from "react-router-dom";
import { HOME } from "../../ApplicationRoutes/RoutesPath";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  logo: {
    backgroundImage: `url(${logoImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    width: 100,
    height: 50,
    display: "inline-block",
    margin: "30px 0px -15px 50px",
  },
  logoJustify: {
    textAlign: "justify",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loadingImg: {
    height: 350,
    width: 450,
  },
  loadingRoot: {
    left: "40%",
    position: "absolute",
    top: "40%",
  },
  root: {
    display: "inline-block",
    width: 700,
    borderRadius: "25px",
    marginLeft: 20,
  },
  result: {
    width: "95%",
    margin: "40px 0px 0px 50px",
    background: "#f8f4ea",
    paddingBottom: 20,
    marginBottom: 20,
    borderRadius: 10,
  },
  resultDetails: {
    width: "98%",
    background: "#ffffff",
    display: "inline-block",
    margin: "20px 20px 0px 20px",
    padding: "25px 0px",
    borderRadius: 25,
    fontSize: 18,
  },
  input: {
    margin: "8px 123px 5px 25px",
    flex: 1,
    width: "50%",
  },
  iconButton: {
    padding: 10,
    float: "right",
  },
  morebutton: {
    float: "right",
  },
  submitbutton: {
    float: "right",
    margin: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  title: {
    flexGrow: 1,
    padding: 20,
    width: "100%",
  },
  titleResource: {
    flexGrow: 1,
    padding: "25px 20px 0px 150px",
    textDecoration: "underline",
    fontWeight: "bolder",
  },
  titleDetials: {
    flexGrow: 1,
    padding: 20,
    textAlign: "left",
  },
  label: { display: "block", textAlign: "left", fontSize: 12 },
  value: { display: "block", textAlign: "left", fontSize: 15 },
}));

export default function SearchError(props) {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.logoJustify}>
        <Link to={HOME}>
          {" "}
          <div className={classes.logo}></div>
        </Link>
        <Paper component="form" className={classes.root}>
          <InputBase
            className={classes.input}
            placeholder="Enter the Resource Plan number "
            inputProps={{ "aria-label": "Enter the Resource Plan number" }}
            value={props.rpNumber !== "" ? props.rpNumber : ""}
            onKeyDown={(e) => {
              props.onKeyPress(e);
            }}
            onChange={(e) => {
              props.setrpNumber(e.target.value);
            }}
          />
          <IconButton
            className={classes.iconButton}
            aria-label="search"
            onClick={(e) => {
              props.onClickfunction(e);
            }}
            size="large">
            <SearchIcon />
          </IconButton>
        </Paper>
      </div>
      {props.isCorsError ? (
        <div id="toPDF">
          <div className={classes.result}>
            <div className={classes.resultDetails}>
              Something went wrong. Please try again after sometime.
            </div>
          </div>
        </div>
      ) : (
        <div id="">
          <div className={classes.result}>
            <div className={classes.resultDetails}>
              Your entered RPLN number <strong> {props.rpNumber} </strong> was
              in the incorrect format. Please enter a resource plan in the
              standard format- RPLNXXXXXXX (RPLN followed by 7-digits).
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
