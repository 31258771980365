import { StyledEngineProvider } from "@mui/material/styles";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { AuthenticationProvider } from "@sede-x/auth";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import ApplicationRoutes from "./ApplicationRoutes/ApplicationRoutes";
import ErrorPage from "./Pages/ErrorPage/ErrorPage";


const theme = createTheme();

const env = process.env && Object.keys(process.env).length > 0 ? process.env : window.env;
console.log(window.env);
let authConfigurationProps={};
if(env)
{
    authConfigurationProps = {
      issuer: env.REACT_APP_SSO_ISSUER,
      clientId: env.REACT_APP_CLIENTID,
      redirectUrl: env.REACT_APP_REDIRECTURL,
      endpointsCofig: {
        authorizationEndpoint: env.REACT_APP_AUTH_URL,
        tokenEndpoint: env.REACT_APP_TOKEN_URL,
        revocationEndpoint: env.REACT_APP_REVOCATION_URL,
        userInfoEndpoint: env.REACT_APP_USER_INFO_URL,
    }
  };
}
const hasEnvironmentVariables =
  authConfigurationProps &&
  Object.keys(authConfigurationProps).every(
    (environment) => authConfigurationProps[environment] !== undefined,
  );



function App() {

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {hasEnvironmentVariables ? (
          <AuthenticationProvider {...authConfigurationProps} scopes={["openid", "profile"]}>
            <BrowserRouter>
              <ApplicationRoutes />
            </BrowserRouter>
          </AuthenticationProvider>
        ) : (
          <ErrorPage statusCode={500} message="Some issue on our side" />
        )}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
