import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";

import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import Typography from "@mui/material/Typography";
import { Grid2 as Grid } from "@mui/material";

import SearchResultRedeploymentJson from "./SearchResultRedeployment.json";
// console.log(SearchResultRedeploymentJson);

const useStyles = makeStyles((theme) => ({
  titleDetials: {
    padding: "10px 15px",
  },
  resultDetailsRedeployment: {
    width: "98%",
    background: "#ffffff",
    margin: "20px 0px 0px 25px",
    borderRadius: 25,
    fontSize: 15,
    outline: "3px solid #abb3e2",
    borderLeft: "5px solid #3f51b5",
  },
  iconButtonRedeployment: {
    width: "97%",
  },
  titleRedeployment: {
    flexGrow: 1,
    padding: 20,
    width: "100%",
    fontSize: 15,
  },
  curentPrjRedeployment: {
    flexGrow: 1,
    width: "100%",
    fontSize: 15,
  },
  label: { display: "block", textAlign: "left", fontSize: 12 },
  value: {
    display: "block",
    textAlign: "left",
    fontSize: 14,
    overflowWrap: "break-word",
  },
  iconButtonspacing: {
    float: "right",
    margin: "-10px 35px",
  },
}));

export default function SearchResultRedeployment(props) {
  const classes = useStyles();

  const project1PriorityList = props.element[1].project1Priority
    ? props.element[1].project1Priority.split(";")
    : null;
  const project2PriorityList = props.element[1].project2Priority
    ? props.element[1].project2Priority.split(";")
    : null;
  const project3PriorityList = props.element[1].project3Priority
    ? props.element[1].project3Priority.split(";")
    : null;
  const project4PriorityList = props.element[1].project4Priority
    ? props.element[1].project4Priority.split(";")
    : null;
  const project1PortfolioList = props.element[1].project1Portfolio
    ? props.element[1].project1Portfolio.split(";")
    : null;
  const project2PortfolioList = props.element[1].project2Portfolio
    ? props.element[1].project2Portfolio.split(";")
    : null;
  const project3PortfolioList = props.element[1].project3Portfolio
    ? props.element[1].project3Portfolio.split(";")
    : null;
  const project4PortfolioList = props.element[1].project4Portfolio
    ? props.element[1].project4Portfolio.split(";")
    : null;
  // console.log("props.props.element", props.element);
  return (
    <div>
      <Paper elevation={0} className={classes.resultDetailsRedeployment}>
        <Typography variant="h6" className={classes.titleRedeployment}>
          Resource Name : {props.element[1].resourceName}
          {props.noOfRecord > 1 ? (
            props.openTabRedeployment.indexOf(props.index) < 0 ? (
              <IconButton
                className={classes.iconButtonspacing}
                aria-label="search"
                onClick={() => props.onExpandRedeployment(props.index)}
                size="large"
              >
                <ExpandMoreIcon />
              </IconButton>
            ) : (
              <IconButton
                className={classes.iconButtonspacing}
                aria-label="search"
                onClick={() => props.onCollapseRedeployment(props.index)}
                size="large"
              >
                <ExpandLessIcon />
              </IconButton>
            )
          ) : null}
        </Typography>
        <Divider />
        <Grid container>
          {SearchResultRedeploymentJson.searchResultRedeployment.map(
            (element, index) => {
              if (element.value === "requestedSkill") {
                return (
                  <Grid size={{lg:element.grid_space, md:element.grid_space}}>
                    <Typography
                      variant="h6"
                      flexWrap="wrap"
                      className={classes.titleDetials}
                    >
                      <span className={classes.label}> {element.key}</span>
                      <span className={classes.value}>
                        {(props.element[1].requestedSkill1 !== ""
                          ? props.element[1].requestedSkill1 + ", "
                          : "") +
                          (props.element[1].requestedSkill2 !== ""
                            ? props.element[1].requestedSkill2 + ", "
                            : "") +
                          (props.element[1].requestedSkill3 !== ""
                            ? props.element[1].requestedSkill3
                            : " ")}
                      </span>
                    </Typography>
                  </Grid>
                );
              }
              if (element.value === "requestedSkill") {
              }
              return (
                <Grid size={{lg:element.grid_space, md:element.grid_space}}>
                  <Typography
                    variant="h6"
                    flexWrap="wrap"
                    className={classes.titleDetials}
                  >
                    <span className={classes.label}> {element.key}</span>
                    <span className={classes.value}>
                      {props.element[1][element.value] !== ""
                        ? props.element[1][element.value]
                        : "None"}
                    </span>
                  </Typography>
                </Grid>
              );
            }
          )}
          {(project1PriorityList != null ||
            project2PriorityList != null ||
            project3PriorityList != null ||
            project4PriorityList != null) && (
            <Typography variant="h6" className={classes.curentPrjRedeployment}>
              Current Project - Portfolio
              <Divider />
            </Typography>
          )}

          {project1PriorityList != null ? (
            <Grid item lg={12} md={12}>
              <Typography
                variant="h6"
                flexWrap="wrap"
                className={classes.titleDetials}
              >
                <span className={classes.label}>
                  {" "}
                  Project 1 Priority - Portfolio
                </span>

                {project1PriorityList.map((proj1prior, index) => {
                  return (
                    <span className={classes.value}>
                      {proj1prior} - {project1PortfolioList[index]}
                    </span>
                  );
                })}
              </Typography>
            </Grid>
          ) : null}
          {project2PriorityList != null ? (
            <Grid size={{lg:12, md:12}}>
              <Typography
                variant="h6"
                flexWrap="wrap"
                className={classes.titleDetials}
              >
                <span className={classes.label}>
                  {" "}
                  Project 2 Priority - Portfolio
                </span>

                {project2PriorityList.map((proj2prior, index) => {
                  return (
                    <span className={classes.value}>
                      {proj2prior} - {project2PortfolioList[index]}
                    </span>
                  );
                })}
              </Typography>
            </Grid>
          ) : null}
          {project3PriorityList != null ? (
            <Grid size={{lg:12, md:12}}>
              <Typography
                variant="h6"
                flexWrap="wrap"
                className={classes.titleDetials}
              >
                <span className={classes.label}>
                  {" "}
                  Project 3 Priority - Portfolio
                </span>

                {project3PriorityList.map((proj3prior, index) => {
                  return (
                    <span className={classes.value}>
                      {proj3prior} - {project3PortfolioList[index]}
                    </span>
                  );
                })}
              </Typography>
            </Grid>
          ) : null}
          {project4PriorityList != null ? (
            <Grid size={{lg:12, md:12}}>
              <Typography
                variant="h6"
                flexWrap="wrap"
                className={classes.titleDetials}
              >
                <span className={classes.label}>
                  {" "}
                  Project 4 Priority - Portfolio
                </span>

                {project4PriorityList.map((proj4prior, index) => {
                  return (
                    <span className={classes.value}>
                      {proj4prior} - {project4PortfolioList[index]}
                    </span>
                  );
                })}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      </Paper>
    </div>
  );
}
