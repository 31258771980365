import { makeStyles } from "@mui/styles";
import { Autocomplete, Grid2 as Grid, TextField, Typography } from "@mui/material";

import hubs from "./Hub";

import useCustomSearchParams from "../../../hooks/useCustomSearchParams";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5em",
    marginBottom: "1rem",
    height: "2.3em",
    marginTop: "1rem",
  },
  label: {
    background: "#FCD839",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height:"2.2rem",
    minWidth: "5rem",
    padding: "0.40rem 0.55rem 0.40rem 0.55rem",
    marginRight: "0.5em",
    fontSize: "0.8em",
    fontWeight: 600,
    color: "#000",
    fontFamily: 'Shell Fonts, sans-serif',
  },

  hubDropdown: {
    height: "2.3em",
    fontWeight: 500,
    borderColor: theme.palette.divider, // Add this line to use the theme's divider color
  },
}));

const HubComponent = () => {
  const classes = useStyles();
  const { hub, setHub } = useCustomSearchParams();

  const handleHubChange = (e, value)=>{
    if(value){
      setHub(value);
    }
    else{
      setHub("Any");
    }
  }

  return (
    <Grid className={classes.container} size={{xs:12, sm:12, md:12, lg:12, xl:12}}>
      <Typography className={classes.label}>Hub</Typography>
      <Autocomplete
        size="small"
        value={hub}
        className={classes.hubDropdown}
        options={hubs}
        renderInput={(params) => (
          <TextField {...params}/>
        )}
        sx={{
          width:400,
        }}
        onChange={handleHubChange}
      />
    </Grid>
  );
};

export default HubComponent;
