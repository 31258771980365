import { useState, useEffect, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { Autocomplete, Grid2 as Grid, TextField, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { DEFAULT_SKILL_TYPE } from "../../../const";

import ValidateSkills from "../ValidatedSkills/ValidatedSkills";


import useCustomSearchParams from "../../../hooks/useCustomSearchParams";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    marginTop: "1rem",
  },
  label: {
    background: "#FCD839",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    padding: "0.40rem 0.6rem 0.40rem 0.6rem",
    fontSize: "0.65em",
    fontWeight: 700,
    color: "#000",
  },

  ccSearch: {
    display: "flex",
    justifyContent: "center",
    gap: "0.5rem",
    marginTop: "1rem",
  },
  searchDropdown: {
    fontWeight: 500,
    borderColor: theme.palette.divider, // Add this line to use the theme's divider color
  },

  ccSearchInput: {
    width: "20rem",
    fontSize: "0.95em",
    paddingLeft: "1em",
    fontWeight: 500,
    border: "1px solid rgb(222, 226, 230)",
    borderColor: theme.palette.divider, // Add this line to use the theme's divider color
  },
}));

export default function Search() {
  const [skillType, setSkillType] = useState(DEFAULT_SKILL_TYPE); //[skillType, setSkillType
  const [skill, setSkill] = useState(""); //[skill, setSkill
  const [proficiency, setProficiency] = useState("Any"); //[proficiency, setProficiency
  const { skillOptions} = useCustomSearchParams();
  const skillTypes = Object.keys(skillOptions)?.sort((a, b) => a.localeCompare(b));

  const options = skillOptions[`${skillType}`]
    .flat()
    ?.sort((a, b) => a.localeCompare(b));

  const classes = useStyles();
  const { toDate, fromDate, setFromDate, setToDate, setListSkills } =
    useCustomSearchParams();
  const prevSkillRef = useRef();
  const prevProficiencyRef = useRef();

   const handleSkills =(e,value)=>{
    if(value){
      setSkill(value);
    }
   }
  useEffect(() => {
    if (
      (skill !== prevSkillRef.current ||
        proficiency !== prevProficiencyRef.current) &&
      proficiency !== "" &&
      skill !== ""
    ) {
      setListSkills({
        skill: skill,
        proficiency: proficiency,
      });
      prevSkillRef.current = skill;
      prevProficiencyRef.current = proficiency;
    }
  }, [skill, proficiency, setListSkills]);

  const handleProficiency = (e) => setProficiency(e.target.value);

  const handleSetFromDate = (e) => setFromDate(e.target.value);

  const handleSetToDate = (e) => setToDate(e.target.value);

  const handleSkillType = (e) => setSkillType(e.target.value);
  
  const proficiencyTypes = ["Any","Knowledge", "Skill", "Mastery"]

  return (
    <div className="container">
      <p className="note">
        <span className="span-group-p">Note :</span>You can enter multiple
        skills.
      </p>
      <Grid className={classes.ccSearch} container>
        <Grid size={{ xs: 12, sm: 6, md: 3, lg: 3, xl: 3 }} className={classes.ccSearch}>
        <Typography className={classes.label}>Skill Type</Typography>
        <Select
          value={skillType}
          onChange={handleSkillType}
          sx={{ width: 200, height: "2.6em" }}
          placeholder="Skill Type"
        >
          {skillTypes.map((key) => (
            <MenuItem key={key} value={key}>
              {key}
            </MenuItem>
          ))}
        </Select>
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }} className={classes.ccSearch}>
        <Typography className={classes.label}>Skill</Typography>
            <Autocomplete
              size="small"
              value={skill}
              className={classes.searchDropdown}
              options={options}
              renderInput={(params) => (
                <TextField {...params} />
              )}
              sx={{
                width: 300,
              }}
              onChange={handleSkills}
            />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }} className={classes.ccSearch}>
        <Typography className={classes.label}>Proficiency</Typography>
        <Select
          value={proficiency}
          onChange={handleProficiency}
          sx={{ width: 200, height: "2.6em" }}
          placeholder="Proficiency"
        >
          {proficiencyTypes.map((key) => (
            <MenuItem key={key} value={key}>
              {key}
            </MenuItem>
          ))}
        </Select>
        </Grid>
      </Grid>
      <ValidateSkills />
      <p className="note" style={{ marginTop: "0.5rem" }}>
        <b>
          <span className="span-group-p">
            <b>
              Note : Start date and end date is optional. <br />
              By default, availability date will be displayed for next 3 months.
              Enter both start date and end date if you are looking for specific
              period.
            </b>
          </span>
        </b>
      </p>
      <div className={`${classes.ccSearch}`} style={{marginBottom:"1.5rem"}}>
        <Typography className={classes.label}>Start Date</Typography>
        <input
          required
          type="date"
          className={`${classes.ccSearchInput}`}
          value={fromDate}
          onChange={handleSetFromDate}
        />
        <Typography className={classes.label}>End Date</Typography>
        <input
          required
          type="date"
          className={`${classes.ccSearchInput}`}
          value={toDate}
          onChange={handleSetToDate}
        />
      </div>
    </div>
  );
}
