import React from 'react';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { makeStyles } from '@mui/styles';
import { Download } from '@mui/icons-material';
const useStyles = makeStyles((theme) => ({
    extractExcelButton:{
        backgroundColor: "#008443",
        color: "#fff",
        padding: "0.5em",
        borderRadius: "0.5em",
        border: "none",
        cursor: "pointer",
        marginTop: "1em",
        "&:hover": {
            backgroundColor: "#008443",
            color: "#fff",
        }
    }
}));

function extractMatchedSkills(resourceJson) {
    let matchedSkills = "";
    if (resourceJson["Fuzz Matched Skills"]?.includes("}") && resourceJson["Fuzz Matched Skills"]?.includes("{")) {
        let skills = resourceJson["Fuzz Matched Skills"].split(";");
        matchedSkills = skills.reduce((acc, skill) => {
            let skill_name = skill.split("skill:")[1].split(",proficiency:")[0];
            return acc + skill_name + " | ";
        }, "");
    }
    else {
        matchedSkills = resourceJson["Fuzz Matched Skills"];
    }
    return matchedSkills;
}
async function createExcelFileFromJsonData(jsonContent, query) {
    const airm_skill_excel_workbook = new ExcelJS.Workbook();
    airm_skill_excel_workbook.creator = "AI Resource Matching";
    const skillsRoleResourceGroupSheet = airm_skill_excel_workbook.addWorksheet("Resource Details");
   let availabilityMonthColumnsHeaders = jsonContent[0]["availabilityMonthNames"].map((monthName) => {
        return { header: monthName, key: monthName };
    });
    skillsRoleResourceGroupSheet.columns = [
        { header: "Resource Name", key: "Resource Name" },
        { header: "Employee Type", key: "Employee Type" },
        { header: "Role", key: "Role" },
        { header: "Resource Group", key: "Resource Group" },
        { header: "Line Manager", key: "Line Manager" },
        { header: "Location", key: "Location" },
        { header: "Matched Skills", key: "Matched Skills" },
        { header: "Skills", key: "Skills" },
        { header: "Validated Skills", key: "Validated Skills" },
        { header: "Development Skills", key: "Development Skills" },
        ...availabilityMonthColumnsHeaders,
        { header: "Total Available Hours", key: "Available Hours" },
        { header: "Project Number", key: "Project Number" },
        { header: "RPLN Number", key: "RPLN Number" },
        { header: "Resource Request Priority", key: "Resource Request Priority" },
        { header: "Project Name", key: "Project Name" }
    ];
    let todayDate = new Date().toDateString();
    skillsRoleResourceGroupSheet.getRow(1).values =[ ];
    skillsRoleResourceGroupSheet.getCell("A1").value = {
        text: `This report was generated on ${todayDate} Click here to search for the resources under same filters again.`,
        hyperlink: `https://ai-resourcematching-ai-resourcematching-uat.azurewebsites.net/${query}`,
    };
    skillsRoleResourceGroupSheet.getCell("A1").font = {underline: true,name:"ShellBold",italic:true, color:{argb:"FF3363A0"}};
    skillsRoleResourceGroupSheet.mergeCells(1,1,1,skillsRoleResourceGroupSheet.columns.length);
    skillsRoleResourceGroupSheet.getRow(1).height = 35;
    skillsRoleResourceGroupSheet.getRow(1).alignment = { horizontal: "center", vertical: "middle" };
    skillsRoleResourceGroupSheet.getRow(2).values = skillsRoleResourceGroupSheet.columns.map(column => column.header);
    skillsRoleResourceGroupSheet.getRow(2).font = { bold: true };

    for (let i = 1; i <=skillsRoleResourceGroupSheet.columns.length; i++) {
        skillsRoleResourceGroupSheet.getRow(2).getCell(i).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FBCE07" }
        };
    }

    skillsRoleResourceGroupSheet.autoFilter = {
        from: {
            row: 2,
            column: 1
        },
        to:
        {
            row: 2,
            column: skillsRoleResourceGroupSheet.columns.length
        }
    }
    //The following code is used to extract the required data to be displayed in the first sheet of the workbook
    jsonContent.forEach((resourceJson) => {
            let matchedSkills = extractMatchedSkills(resourceJson);
    
            let availability = resourceJson["availabilityHours"].map((hours, index) => {
                return {
                    "Month": resourceJson["availabilityMonthNames"][index],
                    "Hours": hours
                }
            });
          
            let previousProjects = resourceJson["Previous Projects"].split("_+-;_");
            let tableContents = previousProjects.map((content) =>
                content.replace("(=_+", "").replace("+=_)", "").split("_+@#$^=_")
            );
            tableContents = tableContents.map((content) => Object.values(content));
            let baseJSONContent = tableContents.map((tableContent) => {
                return {
                        "Resource Name": resourceJson["Name"],
                        "Employee Type": resourceJson["Employee Type"],
                        "Role": resourceJson["Resource Role"],
                        "Resource Group": resourceJson["Resource Group"],
                        "Line Manager": resourceJson["Line Manager"],
                        "Location": resourceJson["Location"],
                        "Matched Skills": matchedSkills,
                        "Skills": resourceJson["Skills"],
                        "Validated Skills": resourceJson["Validated Skills"] === "[]" ? "None" : resourceJson["Validated Skills"],
                        "Development Skills": resourceJson["Development Skills"] === "[]" ? "None" : resourceJson["Development Skills"],
                        "Available Hours": resourceJson["Available Hours"],
                        "Project Number": tableContent[0],
                        "RPLN Number": tableContent[1],
                        "Resource Request Priority": tableContent[2],
                        "Project Name": tableContent[3],
                        ...availability.reduce((acc, availability) => { acc[availability["Month"]] = availability["Hours"]; return acc; }, {})
                }
            });
            skillsRoleResourceGroupSheet.addRows(baseJSONContent);
        });

    airm_skill_excel_workbook.eachSheet((worksheet) => {
        worksheet.properties = {
            defaultRowHeight: 25,
            defaultColWidth: 20,
        };
    });
    //Final buffer code to extract this xlsx file
    const airm_skill_buffer = await airm_skill_excel_workbook.xlsx.writeBuffer();
    let airm_skill_excel_blob = new Blob([airm_skill_buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(airm_skill_excel_blob, "SkillBasedSearchReport.xlsx");

}
export default function ExcelExtract({ responseData, query }) {
    const classes = useStyles();
    if (responseData.length === 0) {
        return <></>;
    }
    return (
        <div>
            <button onClick={() => createExcelFileFromJsonData(responseData, query)} className={classes.extractExcelButton}>Extract as Excel <Download/></button>
        </div>
    )
}