import axios from "axios";
import { GetKeyValueCookie } from "../Pages/AuthCallBack/Authenticationhelper";


export const fetchAllResult = (searchInput) => {
  return fetchAllResultAsync(searchInput)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
};
export const fetchOptions =()=>{
  return fetchResourceGroupAsync()
  .then((data)=>{
    return data;
  })
  .catch((error)=>{
    throw error;
  });
}

async function fetchAllResultAsync(searchInput) {
  try {
    const response = await axios({
      method: "GET",
      url:
        // "https://airesourcematchingprod.azurewebsites.net/getresourcequeue?searchInput=" +
        "https://airesourcematchingprod.azurewebsites.net/getresourcequeue?searchInput=" +
        searchInput,
      headers: { "Access-Control-Allow-Origin": "*", crossorigin: true,
        "Authorization": "Bearer " + (GetKeyValueCookie("Authorization_Result").accessToken || GetKeyValueCookie("Authorization_Result").access_token)
       },
    });
    const body = await response;
    return body;
  } catch (error) {
    throw error.response;
  }
}

async function fetchResourceGroupAsync() {


    await axios.get("https://airesourcematchingprod.azurewebsites.net/getoptions/getParentResourceGroups",
      {
        headers:
         { "Access-Control-Allow-Origin": "*",
           crossorigin: true ,
          "Authorization": 
          "Bearer " + (GetKeyValueCookie("Authorization_Result")?.accessToken || GetKeyValueCookie("Authorization_Result")?.access_token) ,
        },   
      }
    )
    .then((response)=>{
      localStorage.setItem("ParentResourceGroups", JSON.stringify(response.data["Parent Resource Groups"]));
      localStorage.setItem("Skills", JSON.stringify(response.data["Skills"]));
      localStorage.setItem("ResourceRoles", JSON.stringify(response.data["Resource Roles"]));
    })
    .catch((error)=>{
      console.log(error.response);
    });
}


export async function verifyToken() {
  try {
    const response = await axios.get(
      "https://airesourcematchingprod.azurewebsites.net/validate/verifyUser",
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          crossorigin: true,
          "Authorization": "Bearer " + (GetKeyValueCookie("Authorization_Result").accessToken || GetKeyValueCookie("Authorization_Result").access_token),
        },
      }
    );
    const body = await response;
    return body;
  } catch (error) {
    throw error.response;
  }
}
