import React from "react";

import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandMore";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import SearchResultAvaiableJson from "./SearchResultAvaiable.json";


const useStyles = makeStyles((theme) => ({
  titleDetials: {
    padding: "10px 15px",
  },
  resultDetailsAvaiable: {
    width: "98%",
    background: "#ffffff",
    margin: "20px 0px 0px 25px",
    borderRadius: 25,
    fontSize: 15,
    outline: "3px solid #abb3e2",
    borderLeft: "5px solid #3f51b5",
  },
  iconButtonAvaiable: {
    width: "97%",
  },
  titleAvaiable: {
    flexGrow: 1,
    padding: 20,
    width: "100%",
    fontSize: 15,
  },

  label: { display: "block", textAlign: "left", fontSize: 12 },
  value: {
    display: "block",
    textAlign: "left",
    fontSize: 14,
    overflowWrap: "break-word",
  },
  iconButtonspacing: {
    float: "right",
    margin: "-10px 35px",
  },
}));

export default function SearchResultAvaiable(props) {
  const classes = useStyles();

  return (
    <div>
      <Paper elevation={0} className={classes.resultDetailsAvaiable}>
        <Typography variant="h6" className={classes.titleAvaiable}>
          Resource Name : {props.element[1].resourceName}
          {props.noOfRecord > 1 ? (
            props.openTabAvaiable.indexOf(props.index) < 0 ? (
              <IconButton
                className={classes.iconButtonspacing}
                aria-label="expand"
                onClick={() => props.onExpandAvaiable(props.index)}
              >
                <ExpandMoreIcon />
              </IconButton>
            ) : (
              <IconButton
                className={classes.iconButtonspacing}
                aria-label="collapse"
                onClick={() => props.onCollapseAvaiable(props.index)}
              >
                <ExpandLessIcon />
              </IconButton>
            )
          ) : null}
        </Typography>
        <Divider />
        <Grid container>
          {SearchResultAvaiableJson.searchResultAvaiable.map(
            (element, index) => {

              if (element.value === "requestedSkill") {
                return (
                  <Grid
                    item
                    lg={element.grid_space}
                    md={element.grid_space}
                    key={index}
                  >
                    <Typography
                      variant="h6"
                      flexWrap="wrap"
                      className={classes.titleDetials}
                    >
                      <span className={classes.label}> {element.key}</span>
                      <span className={classes.value}>
                        {(props.element[1].requestedSkill1 !== ""
                          ? props.element[1].requestedSkill1 + ", "
                          : "") +
                          (props.element[1].requestedSkill2 !== ""
                            ? props.element[1].requestedSkill2 + ", "
                            : "") +
                          (props.element[1].requestedSkill3 !== ""
                            ? props.element[1].requestedSkill3
                            : " ")}
                      </span>
                    </Typography>
                  </Grid>
                );
              }
              return (
                <Grid
                  item
                  lg={element.grid_space}
                  md={element.grid_space}
                  key={index}
                >
                  <Typography
                    variant="h6"
                    flexWrap="wrap"
                    className={classes.titleDetials}
                  >
                    <span className={classes.label}> {element.key}</span>
                    <span className={classes.value}>
                      {props.element[1][element.value] !== ""
                        ? props.element[1][element.value]
                        : "None"}
                    </span>
                  </Typography>
                </Grid>
              );
            }
          )}
        </Grid>
      </Paper>
    </div>
  );
}
