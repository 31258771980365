import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';

export default function DisplayCard({children}) {
  return (
    <Card sx={{ minWidth:365, maxWidth:1000 }}>
      <CardActionArea>
        <CardContent>
            {children}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}