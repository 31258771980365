import React, { useState } from "react";
import html2canvas from "html2canvas";
import { Link, useNavigate } from "react-router-dom";

import jsPDF from "jspdf";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Typography from "@mui/material/Typography";
import {Grid2 as Grid} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import logoImage from "../../asset/logo.png";
import loadingGif from "../../asset/loading.gif";

import SearchResultAvaiable from "./SearchResultAvaiable";
import SearchResultRedeployment from "./SearchResultRedeployment";
import SearchResultPlan from "./SearchResultPlan";
import SearchErrorRedeployment from "./SearchErrorRedeployment";
import SearchMessage from "./SearchMessage";

import useCustomSearchParams from "../../hooks/useCustomSearchParams";
import { HOME} from "../../ApplicationRoutes/RoutesPath";
import { Download } from "@mui/icons-material";

// import { fontWeight } from "html2canvas/dist/types/css/property-descriptors/font-weight";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    padding: theme.spacing(2, 4, 3),
  },
  logo: {
    backgroundImage: `url(${logoImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    width: 100,
    height: 50,
    display: "inline-block",
    margin: "30px 0px -15px 50px",
  },
  logoJustify: {
    textAlign: "justify",
  },
  containerDisplay: {
    display: "flex",
  },
  containerButton: {
    marginTop: 10,
    padding: "10px 25px",
  },
  containerTitle: {
    marginTop: 45,
    padding: "5px 15px 0px 15px",
  },
  loadingImg: {
    height: 350,
    width: 450,
  },
  loadingRoot: {
    left: "40%",
    position: "absolute",
    top: "40%",
  },
  rootSearchResultPage: {
    display: "inline-block",
    width: 800,
    borderRadius: "5vw",
    marginLeft: 20,
  },
  modalPaper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
  },
  result: {
    width: "95%",
    margin: "40px 0px 0px 50px",
    background: "#f8f4ea",
    padding: "20px 0px",
    marginBottom: 20,
    borderRadius: 10,
  },
  resultDetails: {
    width: "98%",
    background: "#ffffff",
    color: "#000000",
    margin: "20px 20px 0px 30px",
    padding: "5px 0px",
    borderRadius: 25,
    fontSize: 18,
    fontWeight: "500",
  },
  accordionPanel: {
    padding: 0,
  },
  resultDetailsPanel: {
    width: "98%",
    background: "#ffffff",
    color: "#000000",
    margin: 0,
    padding: "5px 0px",
    borderRadius: 25,
    fontSize: 15,
  },
  input: {
    margin: "8px 0px 5px 25px",
    flex: 1,
    width: "90%",
  },
  iconButton: {
    padding: 10,
    float: "right",
  },
  iconButtonExpand: {
    padding: 10,
    margin: "10px 20px",
    float: "right",
  },
  morebutton: {
    float: "right",
  },
  submitbutton: {
    float: "right",
    margin: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  title: {
    flexGrow: 1,
    padding: 20,
    fontSize: 15,
  },
  titleResource: {
    fontWeight: 500,
    fontSize: 18,
  },
  titleDetials: {
    flexGrow: 1,
    padding: "10px 15px",
  },
  label: { display: "block", textAlign: "left", fontSize: 12 },
  readMore: {
    fontWeight: "bold",
    cursor: "pointer",
    fontSize: 15,
    textDecoration: "underline",
    color: "blue",
  },
  value: {
    display: "block",
    textAlign: "left",
    fontSize: 14,
    overflowWrap: "break-word",
  },
  exportbutton: { marginLeft: 20, backgroundColor: "#3f51b5" },
  marginLeft: { marginLeft: 10 },
  settingContainer: { display: "flex" },
  settingButton: { display: "block" },
  errorMsg1: {
    textAlign: "left",
    display: "block",
    margin: "15px 0px 15px 35px",
    width: "100%",
    fontSize: 16,
  },
  errorMsgNote: {
    textAlign: "left",
    display: "block",
    margin: "15px 0px 15px 35px",
    width: "100%",
    fontSize: 16,
    fontWeight: 700,
  },
  errorMsg1Title: {
    textAlign: "left",
    display: "block",
    marginLeft: "inherit",
    width: "100%",
    fontSize: 18,
    fontWeight: "600",
    paddingTop: 20,
    fontStyle: "italic",
  },
  errorMsg2Title: {
    width: "98%",
    background: "#ffffff",
    display: "inline-block",
    margin: "0px 20px 0px 20px",
    padding: "25px 0px",
    textDecoration: "underline",
    borderRadius: 25,
    fontSize: 18,
    fontWeight: "600",
    fontStyle: "italic",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    padding: "5px 10px",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  coloumnBlock: { display: "inline-block", width: "49%" },
}));

export default function SearchResult(props) {
  const classes = useStyles();
  const { reset } = useCustomSearchParams();
  const navigate = useNavigate();

  const [noOfRecord, setnoOfRecord] = useState(1);
  const [openTabAvaiable, setOpenTabAvaiable] = useState([]);
  const [openTabRedeployment, setOpenTabRedeployment] = useState([]);
  const [readMore, setreadMore] = useState(false);
  const [onlyStaff, setonlyStaff] = useState(false);

  const propsResponse = props.response;
  // let propsResponse.servicenow_Link="https://shell2.service-now.com/nav_to.do?uri=%2Fresource_plan.do%3Fsys_id%3Df3e11358475e315c7ce899a8536d4302"
  const message = propsResponse && propsResponse.message;

  //The below line can be taken out of the comment if the servicenow link is available
  // const serviceNow_Link = propsResponse && propsResponse.servicenow_Link;

  if (message) {
    return (
      <>
        <SearchMessage message={message}>
          <Link to={HOME}>
            <ArrowBackIcon /> Go Back
          </Link>
        </SearchMessage>
      </>
    );
  } else {
    const propsResourcePlanDetail =
      propsResponse && propsResponse.resourcePlanDetails;
    const resourcePlanDetailLength =
      propsResourcePlanDetail && Object.keys(propsResourcePlanDetail).length;

    const propsResourceDetail = propsResponse && propsResponse.resourceDetails;
    const resourceDetailLength =
      propsResourceDetail && Object.keys(propsResourceDetail).length;
    let propsResourceStaffDetail = [];
    propsResourceStaffDetail =
      propsResponse &&
      propsResourceDetail &&
      Object.keys(propsResourceDetail).filter((key, value) => {
        return propsResourceDetail[value].employeeType === "Staff";
      });

    const propsResourceRedeploymentDetail =
      propsResponse && propsResponse.resourceRedeploymentDetails;
    const resourceRedeploymentDetailLength =
      propsResourceRedeploymentDetail &&
      Object.keys(propsResourceRedeploymentDetail).length;
    let propsResourceStaffRedeploymentDetail = [];
    propsResourceStaffRedeploymentDetail =
      propsResponse &&
      propsResourceRedeploymentDetail &&
      Object.keys(propsResourceRedeploymentDetail).filter((key, value) => {
        return propsResourceRedeploymentDetail[value].employeeType === "Staff";
      });

    const propsDisplayMessage = propsResponse && propsResponse.displayMessage;
    var countAvaiable = 0;
    var countRedeploymentAvaiable = 0;

    const toggleReadMore = () => {
      setreadMore(!readMore);
    };
    const handleCheckChange = (event) => {
      setonlyStaff(event.target.checked);
    };
    const onCollapseAvaiable = (index) => {
      if (openTabAvaiable.indexOf(index) >= 0) {
        let noOfIndex = openTabAvaiable.map((e) => {
          return e;
        });
        noOfIndex.splice(openTabAvaiable.indexOf(index), 1);
        setOpenTabAvaiable(noOfIndex);
      }
    };
    const onExpandAvaiable = (index) => {
      if (openTabAvaiable.length < 2) {
        if (openTabAvaiable.indexOf(index) < 0) {
          let noOfIndex = openTabAvaiable.map((e) => {
            return e;
          });
          noOfIndex.push(index);
          setOpenTabAvaiable(noOfIndex);
        }
      } else {
        if (openTabAvaiable.indexOf(index) < 0) {
          let noOfIndex = openTabAvaiable.map((e) => {
            return e;
          });
          noOfIndex.shift();
          noOfIndex.push(index);
          setOpenTabAvaiable(noOfIndex);
        }
      }
    };
    const onCollapseRedeployment = (index) => {
      if (openTabRedeployment.indexOf(index) >= 0) {
        let noOfIndex = openTabRedeployment.map((e) => {
          return e;
        });
        noOfIndex.splice(openTabRedeployment.indexOf(index), 1);
        setOpenTabRedeployment(noOfIndex);
      }
    };
    const onExpandRedeployment = (index) => {
      if (openTabRedeployment.length < 2) {
        if (openTabRedeployment.indexOf(index) < 0) {
          let noOfIndex = openTabRedeployment.map((e) => {
            return e;
          });
          noOfIndex.push(index);
          setOpenTabRedeployment(noOfIndex);
        }
      } else {
        if (openTabRedeployment.indexOf(index) < 0) {
          let noOfIndex = openTabRedeployment.map((e) => {
            return e;
          });
          noOfIndex.shift();
          noOfIndex.push(index);
          setOpenTabRedeployment(noOfIndex);
        }
      }
    };
    const onDownLoad = (e, props) => {
      const input = document.getElementById("toPDF");
      html2canvas(input).then((canvas) => {
        var wid = canvas.width;
        var hgt = canvas.height;
        var img = canvas.toDataURL("image/png");
        var hratio = hgt / wid;
        var doc = new jsPDF({
          orientation: "p", // landscape
          unit: "mm", // points, pixels won't work properly
          format: [canvas.width, canvas.height], // set needed dimensions for any element
        });
        var width = doc.internal.pageSize.width;
        var height = width * hratio;
        doc.addImage(img, "JPEG", 0, 0, width, height);
        doc.save(
          noOfRecord === 1
            ? props.rpNumber.toUpperCase() + "_Best_Match.pdf"
            : props.rpNumber.toUpperCase() + "_Top" + noOfRecord + ".pdf"
        );
      });
    };

    const handleBackButton = () => {
      reset();
      navigate(HOME);
    };
    return (
      <div>
        <div className={classes.logoJustify}>
        <Link to={HOME}>
          {" "}
          <div className={classes.logo}></div>
        </Link>
          <Paper component="form" className={classes.rootSearchResultPage}>
            <InputBase
              className={classes.input}
              placeholder="Enter the Resource Plan number "
              inputProps={{ "aria-label": "Enter the Resource Plan number" }}
              value={props.rpNumber !== "" ? props.rpNumber : ""}
              onKeyDown={(e) => {
                if (!props.responseSet) props.onKeyPress(e);
                else {
                  e.preventDefault();
                }
                setnoOfRecord(1);
                setreadMore(false);
              }}
              onChange={(e) => {
                props.setrpNumber(e.target.value);
              }}
            />
            <IconButton
              className={classes.iconButton}
              disabled={props.responseSet}
              aria-label="search"
              onClick={(e) => {
                props.onClickfunction(e);
                setnoOfRecord(1);
                setreadMore(false);
              }}
              size="large"
            >
              <SearchIcon />
            </IconButton>
          </Paper>
          <Button
            variant="contained"
            color="primary"
            disabled={
              !(
                propsResponse !== undefined &&
                propsResourceDetail !== undefined &&
                resourcePlanDetailLength > 0 &&
                resourceDetailLength > 0
              )
            }
            className={classes.exportbutton}
            onClick={(e) => {
              onDownLoad(e, props);
            }}
            startIcon={<Download/>}
          >
            Export to PDF
          </Button>
        </div>
        <button className="back_btn" onClick={handleBackButton}>
          <ArrowBackIcon /> Go Back
        </button>
        {/* <button className='back_btn' onClick={()=>history.push()}> </button> */}
        {propsResourcePlanDetail === undefined && (
          <div className={classes.loadingRoot}>
            <img
              src={loadingGif}
              alt="Loading"
              className={classes.loadingImg}
            />
          </div>
        )}
        <div id="toPDF">
          {propsResourcePlanDetail && (
            <div className={classes.result}>
              {propsResourcePlanDetail && resourcePlanDetailLength > 0 && (
                <SearchResultPlan
                  propsResourcePlanDetail={propsResourcePlanDetail}
                  toggleReadMore={toggleReadMore}
                  readMore={readMore}
                />
              )}
              {((propsResourceDetail && resourceDetailLength > 0) ||
                (propsResourceRedeploymentDetail &&
                  resourceRedeploymentDetailLength > 0)) && (
                <div className={classes.containerButton}>
                  <ButtonGroup
                    size="large"
                    color="primary"
                    aria-label="large primary button group"
                    className={classes.morebutton}
                  >
                    <Button
                      disabled={
                        !(
                          resourceDetailLength >= 1 ||
                          resourceRedeploymentDetailLength >= 1
                        )
                      }
                      variant={noOfRecord === 1 ? "contained" : null}
                      onClick={() => setnoOfRecord(1)}
                    >
                      Best Match
                    </Button>
                    <Button
                      variant={noOfRecord === 5 ? "contained" : null}
                      disabled={
                        !(
                          resourceDetailLength >= 2 ||
                          resourceRedeploymentDetailLength >= 2
                        )
                      }
                      onClick={() => setnoOfRecord(5)}
                    >
                      Top (5)
                    </Button>
                  </ButtonGroup>
                  <FormGroup
                    size="large"
                    aria-label="large primary button group"
                    className={classes.morebutton}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="large"
                          defaultChecked
                          color="primary"
                          checked={onlyStaff}
                          onChange={handleCheckChange}
                        />
                      }
                      size="large"
                      label="Staff only"
                    />
                  </FormGroup>
                </div>
              )}
              {(resourceDetailLength > 0 ||
                resourcePlanDetailLength > 0 ||
                resourceRedeploymentDetailLength > 0) && (
                <div className={classes.containerTitle}>
                  <Grid container>
                    <Grid item lg={6} md={6}>
                      <Typography
                        variant="h5"
                        className={classes.titleResource}
                      >
                        {noOfRecord === 1
                          ? "Best Match - Available Resource "
                          : noOfRecord > 1 &&
                            resourceDetailLength < 5 &&
                            !onlyStaff
                          ? "Top " +
                            resourceDetailLength +
                            " - Available Resource"
                          : noOfRecord > 1 &&
                            propsResourceStaffDetail.length < 5 &&
                            onlyStaff
                          ? "Top " +
                            propsResourceStaffDetail.length +
                            " - Available Resource"
                          : "Top 5 - Available Resource"}
                      </Typography>
                    </Grid>

                    <Grid item lg={6} md={6}>
                      <Typography
                        variant="h5"
                        className={classes.titleResource}
                      >
                        {noOfRecord === 1
                          ? "Best Match for Redeployment"
                          : noOfRecord > 1 &&
                            resourceRedeploymentDetailLength < 5 &&
                            !onlyStaff
                          ? "Top " +
                            resourceRedeploymentDetailLength +
                            " Matches for Redeployment"
                          : noOfRecord > 1 &&
                            propsResourceStaffRedeploymentDetail.length < 5 &&
                            onlyStaff
                          ? "Top " +
                            propsResourceStaffRedeploymentDetail.length +
                            " Matches for Redeployment"
                          : "Top 5 Matches for Redeployment"}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              )}
              {(resourceDetailLength > 0 ||
                resourcePlanDetailLength > 0 ||
                resourceRedeploymentDetailLength > 0) && (
                <div className={classes.containerDisplay}>
                  <div className={classes.coloumnBlock}>
                    {propsResourceDetail &&
                      resourceDetailLength > 0 &&
                      Object.entries(propsResourceDetail).map(
                        (element, index) => {
                          if (countAvaiable < noOfRecord) {
                            if (
                              noOfRecord > 1 &&
                              openTabAvaiable.indexOf(index) < 0 &&
                              (onlyStaff === false ||
                                (propsResourceStaffDetail !== undefined &&
                                  propsResourceStaffDetail.indexOf(
                                    index.toString()
                                  ) > -1))
                            ) {
                              countAvaiable++;

                              return (
                                <Paper
                                  elevation={0}
                                  className={classes.resultDetails}
                                >
                                  <Grid container>
                                    <Grid item lg={3} md={3}>
                                      <Typography
                                        variant="h6"
                                        flexWrap="wrap"
                                        className={classes.titleDetials}
                                      >
                                        <span className={classes.label}>
                                          {" "}
                                          Resource Name
                                        </span>
                                        <span className={classes.value}>
                                          {element[1].resourceName !== ""
                                            ? element[1].resourceName
                                            : "None"}
                                        </span>
                                      </Typography>
                                    </Grid>
                                    <Grid item lg={3} md={3}>
                                      <Typography
                                        variant="h6"
                                        flexWrap="wrap"
                                        className={classes.titleDetials}
                                      >
                                        <span className={classes.label}>
                                          {" "}
                                          Line Manager
                                        </span>
                                        <span className={classes.value}>
                                          {element[1].lineManager !== ""
                                            ? element[1].lineManager
                                            : "None"}
                                        </span>
                                      </Typography>{" "}
                                    </Grid>
                                    <Grid item lg={3} md={3}>
                                      <Typography
                                        variant="h6"
                                        flexWrap="wrap"
                                        className={classes.titleDetials}
                                      >
                                        <span className={classes.label}>
                                          {" "}
                                          Role
                                        </span>
                                        <span className={classes.value}>
                                          {element[1].resourceRoles !== ""
                                            ? element[1].resourceRoles
                                            : "None"}
                                        </span>
                                      </Typography>
                                    </Grid>
                                    <Grid item lg={2} md={2}>
                                      <Typography
                                        variant="h6"
                                        flexWrap="wrap"
                                        className={classes.titleDetials}
                                      >
                                        <span className={classes.label}>
                                          {" "}
                                          Hub
                                        </span>
                                        <span className={classes.value}>
                                          {element[1].hub !== ""
                                            ? element[1].hub
                                            : "None"}
                                        </span>
                                      </Typography>
                                    </Grid>
                                    <Grid item lg={1} md={1}>
                                      <IconButton
                                        className={classes.iconButtonExpand}
                                        aria-label="expand"
                                        onClick={() => onExpandAvaiable(index)}
                                        size="large"
                                      >
                                        <ExpandMoreIcon />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                </Paper>
                              );
                            }
                            if (
                              onlyStaff === false ||
                              (propsResourceStaffDetail !== undefined &&
                                propsResourceStaffDetail.indexOf(
                                  index.toString()
                                ) > -1)
                            ) {
                              countAvaiable++;
                              return (
                                <SearchResultAvaiable
                                  propsResourceStaffDetail={
                                    propsResourceStaffDetail
                                  }
                                  element={element}
                                  index={index}
                                  onExpandAvaiable={(index) => {
                                    onExpandAvaiable(index);
                                  }}
                                  onCollapseAvaiable={(index) => {
                                    onCollapseAvaiable(index);
                                  }}
                                  openTabAvaiable={openTabAvaiable}
                                  noOfRecord={noOfRecord}
                                  resourceDetailLength={resourceDetailLength}
                                />
                              );
                            }
                          }
                          return null;
                        }
                      )}

                    {propsResourcePlanDetail &&
                    !onlyStaff &&
                    resourceDetailLength <= 0 ? (
                      <SearchErrorRedeployment
                        message={
                          "No available resources are a match for this RPLN, please look at redeployment options"
                        }
                      />
                    ) : propsResourcePlanDetail &&
                      onlyStaff &&
                      propsResourceStaffDetail.length < 1 ? (
                      <SearchErrorRedeployment
                        message={
                          "No available staff are a match for this RPLN, please look at redeployment options"
                        }
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className={classes.coloumnBlock}>
                    {propsResourceRedeploymentDetail &&
                      resourceRedeploymentDetailLength > 0 &&
                      Object.entries(propsResourceRedeploymentDetail).map(
                        (element, index) => {
                          if (countRedeploymentAvaiable < noOfRecord) {
                            if (
                              noOfRecord > 1 &&
                              openTabRedeployment.indexOf(index) < 0 &&
                              (onlyStaff === false ||
                                (propsResourceStaffRedeploymentDetail !==
                                  undefined &&
                                  propsResourceStaffRedeploymentDetail.indexOf(
                                    index.toString()
                                  ) > -1))
                            ) {
                              countRedeploymentAvaiable++;
                              return (
                                <div>
                                  <Paper
                                    elevation={0}
                                    className={classes.resultDetails}
                                  >
                                    <Grid container>
                                      <Grid item lg={3} md={3}>
                                        <Typography
                                          variant="h6"
                                          flexWrap="wrap"
                                          className={classes.titleDetials}
                                        >
                                          <span className={classes.label}>
                                            {" "}
                                            Resource Name
                                          </span>
                                          <span className={classes.value}>
                                            {element[1].resourceName !== ""
                                              ? element[1].resourceName
                                              : "None"}
                                          </span>
                                        </Typography>
                                      </Grid>
                                      <Grid item lg={3} md={3}>
                                        <Typography
                                          variant="h6"
                                          flexWrap="wrap"
                                          className={classes.titleDetials}
                                        >
                                          <span className={classes.label}>
                                            {" "}
                                            Line Manager
                                          </span>
                                          <span className={classes.value}>
                                            {element[1].lineManager !== ""
                                              ? element[1].lineManager
                                              : "None"}
                                          </span>
                                        </Typography>{" "}
                                      </Grid>
                                      <Grid item lg={3} md={3}>
                                        <Typography
                                          variant="h6"
                                          flexWrap="wrap"
                                          className={classes.titleDetials}
                                        >
                                          <span className={classes.label}>
                                            {" "}
                                            Role
                                          </span>
                                          <span className={classes.value}>
                                            {element[1].resourceRoles !== ""
                                              ? element[1].resourceRoles
                                              : "None"}
                                          </span>
                                        </Typography>
                                      </Grid>
                                      <Grid item lg={2} md={2}>
                                        <Typography
                                          variant="h6"
                                          flexWrap="wrap"
                                          className={classes.titleDetials}
                                        >
                                          <span className={classes.label}>
                                            {" "}
                                            Hub
                                          </span>
                                          <span className={classes.value}>
                                            {element[1].hub !== ""
                                              ? element[1].hub
                                              : "None"}
                                          </span>
                                        </Typography>
                                      </Grid>

                                      <Grid item lg={1} md={1}>
                                        <IconButton
                                          className={classes.iconButtonExpand}
                                          aria-label="search"
                                          onClick={() =>
                                            onExpandRedeployment(index)
                                          }
                                          size="large"
                                        >
                                          <ExpandMoreIcon />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  </Paper>
                                </div>
                              );
                            }
                            if (
                              onlyStaff === false ||
                              (propsResourceStaffRedeploymentDetail !==
                                undefined &&
                                propsResourceStaffRedeploymentDetail.indexOf(
                                  index.toString()
                                ) > -1)
                            ) {
                              countRedeploymentAvaiable++;
                              return (
                                <SearchResultRedeployment
                                  propsResourceStaffDetail={
                                    propsResourceStaffRedeploymentDetail
                                  }
                                  element={element}
                                  index={index}
                                  onExpandRedeployment={(index) => {
                                    onExpandRedeployment(index);
                                  }}
                                  onCollapseRedeployment={(index) => {
                                    onCollapseRedeployment(index);
                                  }}
                                  openTabRedeployment={openTabRedeployment}
                                  noOfRecord={noOfRecord}
                                  resourceDetailLength={
                                    resourceRedeploymentDetailLength
                                  }
                                />
                              );
                            }
                          }

                          return <></>;
                        }
                      )}

                    {propsResourcePlanDetail &&
                    propsResourcePlanDetail.priority &&
                    propsResourcePlanDetail.priority.indexOf("Regular") >= 0 &&
                    resourceRedeploymentDetailLength <= 0 ? (
                      <SearchErrorRedeployment
                        message={
                          "No re-deployment options available for this RPLN, as it is a 'P4 - Regular' request."
                        }
                      />
                    ) : propsResourcePlanDetail &&
                      !onlyStaff &&
                      resourceRedeploymentDetailLength <= 0 ? (
                      <SearchErrorRedeployment
                        message={
                          "No resources for re-deployment available currently for this RPLN"
                        }
                      />
                    ) : propsResourcePlanDetail &&
                      onlyStaff &&
                      propsResourceStaffRedeploymentDetail.length < 1 ? (
                      <SearchErrorRedeployment
                        message={
                          "No staff for re-deployment available currently for this RPLN."
                        }
                      />
                    ) : (
                      <></>
                    )}
                    {/* {propsResourceRedeploymentDetail &&
                  (resourceRedeploymentDetailLength <= 0 ||
                    propsResourceStaffRedeploymentDetail <= 0) && (
                    <SearchErrorRedeployment
                      message={
                        "No resource is currently available to Redeploy for this resource plan."
                      }
                    />
                  )} */}
                  </div>
                </div>
              )}
              {resourceDetailLength === 0 &&
                resourcePlanDetailLength === 0 &&
                resourceRedeploymentDetailLength === 0 && (
                  <div className={classes.resultDetails}>
                    <span className={classes.errorMsg1Title}>
                      The resource plan you've entered in not valid. Please
                      ensure-
                    </span>
                    <span className={classes.errorMsg1}>
                      {`1) The Resource Plan is currently active in ServiceNow.`}
                    </span>
                    <span className={classes.errorMsg1}>
                      {`2) The state of the RPLN is 'Requested'.`}
                    </span>
                    <span className={classes.errorMsgNote}>
                      {`Note - Resource plans requested less than 24 hours before might still be updating in the database. Please try again after a few hours.`}
                    </span>
                  </div>
                )}
              {/* {resourceDetailLength === 0 && resourcePlanDetailLength > 0 && (
              <div className={classes.resultDetails}>
                <div className={classes.errorMsg2Title}>
                  The Resource Plan requested doesn't have any match in the
                  organization currently. The recommendation is to go for
                  Contractor Procurement.
                </div>
              </div>
            )} */}
              {propsDisplayMessage !== undefined && (
                <div className={classes.resultDetails}>
                  <div className={classes.errorMsg2Title}>
                    {propsDisplayMessage}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
