import { Link } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import image from "../../asset/Banner.jpg";
import logoImage from "../../asset/logo.png";

import { HOME } from "../../ApplicationRoutes/RoutesPath";

const useStyles = makeStyles((theme) => ({
  searchbox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    marginTop: "8vh",
    marginBottom: "2vh",
    height: "20vh",
    width: "30vw",
  },
  banner: {
    backgroundImage: `url(${image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    height: "20vh",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    padding: "2vh 2vh",
    display: "flex",
    width: "60vw",
    borderRadius: "5vw",
  },
  instruction: {
    padding: "5vh",
    borderRadius: "50px",
    width: "100%",
    lineHeight: "1.5",
    fontSize: "medium",
    fontWeight: "500",
    backgroundColor: "white",
  },
  input: {
    marginLeft: "20px",
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  instructionHeader: {
    display: "contents",
    fontWeight: "700",
    fontSize: "1.5em",
    marginBottom: "5vh",
  },
}));

export default function Search(props) {
  const classes = useStyles();

  return (
    <>
      <Link className="back_btn" to={HOME}>
        <ArrowBackIcon className={classes.iconArrow} /> Home
      </Link>

      <div className={classes.searchbox}>
        <img src={logoImage} className={classes.logo} alt="logo" />
        <div className={classes.banner}>
          <Paper className={classes.root}>
            <InputBase
              className={classes.input}
              placeholder="Enter the Resource Plan number "
              inputProps={{ "aria-label": "Enter the Resource Plan number" }}
              onKeyDown={(e) => {
                props.onKeyPress(e);
              }}
              onChange={(e) => {
                props.setrpNumber(e.target.value);
              }}
            />
            <IconButton
              className={classes.iconButton}
              aria-label="search"
              onClick={(e) => {
                props.onClickfunction(e);
              }}
              size="large"
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>
        <Paper elevation={0} className={classes.instruction}>
          <div className={classes.instructionHeader}> How it works?</div>
          <br />
          1. Enter a Resource Plan number currently in requested state.
          <br />
          2. The AI solution runs through a data set of 10+ parameters spread
          over 25,000+ past allocations to fetch the best match(es) for the
          request.
          <br />
          3. The search aims to also provide details for why the match is the
          most preferred choice, which can be used for further engagements.
          <br />
        </Paper>
      </div>
    </>
  );
}
