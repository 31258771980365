import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import { Autocomplete, Grid2 as Grid, Tooltip, Typography } from "@mui/material";

import { INPUT_AUTO_COMPLETE_TYPE } from "../../../const";

import useCustomSearchParams from "../../../hooks/useCustomSearchParams";
import { useState } from "react";
import DisplayCard from "../DisplayCard/DisplayCard";
import { Close } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  ccInput: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5em",
    marginTop: "1rem",
    marginBottom: "1rem",
    height: "2.3em",
  },
  label: {
    display: "flex",
    alignItems: "center",
    padding: "0.40rem 0.55rem 0.40rem 0.55rem",
    fontSize: "0.9em",
    fontWeight: 500,
    color: "#000",
    fontFamily: 'Shell Fonts, sans-serif',
  },
  autoInput: {
    fontSize: "0.5em",
  },
  rg_names:{
    fontSize: "0.8rem",
    backgroundColor: "rgba(0, 60, 136, 0.1)",
    padding: "0.5em",
    listStyle: "none",
    marginLeft: "2em",
    borderRadius: "0.5em",
    },
    rg_list:
    {
        listStyle: "none",
        height:"1rem",
        margin: "0.5em",
    },
    options_container:{
      display: "flex",
      flexWrap: "wrap",
      height: "5rem",
      width: "auto",
      overflowY: "scroll",
  },
  delete_btn: {
    background: "none",
    color: "red",
    marginLeft: "0.5em",
    padding: "0.2em",
    border: "none",
  },
}));

export default function CapabilityCenter() {
  const classes = useStyles();
  const { lob, setLob, setCapCenter, groups, deleteCapCenter, deleteLob, capCenter } = useCustomSearchParams();
  const keys = groups? Object.keys(groups) : [];

  const [resourceGroup, setResourceGroup] = useState("Any");
  const [parentResourceGroup, setParentResourceGroup] = useState("Any");
 
  const options = groups && lob.reduce(
    (oldList, rg) => [...oldList, ...groups[rg]], []
  ) ? lob.reduce((oldList,rg)=>[...oldList, ...groups[rg]],["Any"])  : [];
  
 const handleParentResourceGroupChange = (e, value) => {
    if (value !== "") {
      setParentResourceGroup(value);
      setLob(value);
    }
    else {
      setParentResourceGroup("Any");
      setLob("Any");
    }
  }
  const handleResourceGroupChange = (e, value) => {
    if (value !== "") {
      setResourceGroup(value);
      setCapCenter(value); // Update capCenter directly with the new value
    }
    else {
      setResourceGroup("Any");
      setCapCenter("Any");
    }
  }
  const handleDelete = (rg) => {
    deleteCapCenter(rg);
  };

  const handleParentResourceGroupDelete = (parentResourceGroup) => {
    deleteLob(parentResourceGroup);
  }
  return (
    <div>
      <Grid className={`${classes.ccInput}`} container>
        <Grid size={{xs:12, sm:12, md:5, lg:5, xl:5}}>
        <DisplayCard>
          <Typography className={classes.label}>Parent Resource Group</Typography>
          <Autocomplete
            value={parentResourceGroup}
            options={keys}
            size="small"
            onChange={handleParentResourceGroupChange}
            sx={{ width: 380 }}
            type={INPUT_AUTO_COMPLETE_TYPE[1]}
            placeholder="Line of Business"
            className={classes.autoInput}
            renderInput={(params) => (
              <TextField {...params} />
            )}
          />   
          <Grid size={{xs:12, sm:12, md:12, lg:12, xl:12}} className={classes.options_container}>
           {lob &&
              lob.map((element_lob) => (
                <li key={element_lob} className={classes.rg_list}>
                  <Tooltip title={element_lob} placement="top">
                  <span className={classes.rg_names}>{element_lob.slice(0,15)}
                    {element_lob.length > 15 ? "..." : ""}
                    <button className={classes.delete_btn} onClick={() =>handleParentResourceGroupDelete(element_lob)}>
                      <Close/>
                    </button>
                  </span>
                  </Tooltip>
                </li>
              ))
            }
            </Grid>
        </DisplayCard>
        </Grid>
        <Grid size={{xs:12, sm:12, md:5, lg:5, xl:5}}>
        <DisplayCard>
            <Typography className={classes.label}>Resource Group</Typography>
            <Autocomplete
            size="small"
              value={resourceGroup}
              options={options}
              sx={{ width: 380 }}
              onChange={handleResourceGroupChange}
              type={INPUT_AUTO_COMPLETE_TYPE[1]}
              className={classes.autoInput}
              renderInput={(params) => (
                <TextField {...params} />
              )}
            />
            <Grid size={{xs:12, sm:12, md:12, lg:12, xl:12}} className={classes.options_container}>
              {
                capCenter?.map((cc) => (
                  <li key={cc} className={classes.rg_list}>
                    <Tooltip title={cc} placement="top">
                    <span className={classes.rg_names}>{cc.slice(0,15)}{cc.length > 15? "..." : ""}
                      <button className={classes.delete_btn} onClick={() => handleDelete(cc)}>
                        <Close/>
                      </button>
                    </span>
                    </Tooltip>
                  </li>
                ))
              }
            </Grid>
        </DisplayCard>
        </Grid>
      </Grid>
    </div>
  );
}
