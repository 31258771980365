import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";

import Typography from "@mui/material/Typography";
import { Grid2 as Grid } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  resultDetails: {
    fontWeight:'bolder',
    width: "97%",
    background: "#ffffff",
    color: "#000000",
    margin: "20px 20px 0px 20px",
    padding: "5px 0px",
    borderRadius: 25,
    fontSize: "1em",
    outline: "3px solid #abb3e2",
    borderLeft: "5px solid #3f51b5",
  },
  title: {
    flexGrow: 1,
    padding: 20,
    fontSize: 15,
  },
  titleDetails: {
    flexGrow: 1,
    padding: 20,
  },
  label: { display: "block", textAlign: "left", fontSize: 12 },
  readMore: {
    fontWeight: "bold",
    cursor: "pointer",
    fontSize: 15,
    textDecoration: "underline",
    color: "blue",
  },
  value: {
    display: "block",
    textAlign: "left",
    fontSize: 15,
    overflowWrap: "break-word",

  },
  valueJustify: {
    display: "block",
    textAlign: "justify",
    fontSize: 15,
    overflowWrap: "break-word",
  },
}));

export default function SearchResultPlan(props) {
  const classes = useStyles();

  // console.log("props.element", props.element);
  return (
    <Paper elevation={0} className={classes.resultDetails}>
      <Typography variant="h6" className={classes.title}>
        Resource Plan Details
      </Typography>
      <Divider />
      <Grid container style={{fontWeight:'bolder !important'}}>
        <Grid size={{lg:3, md:3, xs:12, sm:12}}>
          <Typography
            variant="h6"
            flexWrap="wrap"
            className={classes.titleDetails}
          >
            <span className={classes.label}> Role</span>
            <span className={classes.value}>
              {props.propsResourcePlanDetail.role !== ""
                ? props.propsResourcePlanDetail.role
                : "None"}
            </span>
          </Typography>
        </Grid>
        <Grid size={{lg:3, md:3, xs:12, sm:12}}>
          <Typography
            variant="h6"
            flexWrap="wrap"
            className={classes.titleDetails}
          >
            <span className={classes.label}> Hub</span>
            <span className={classes.value}>
              {props.propsResourcePlanDetail.hub !== ""
                ? props.propsResourcePlanDetail.hub
                : "None"}
            </span>
          </Typography>
        </Grid>

        <Grid size={{lg:3, md:3, xs:12, sm:12}}>
          <Typography
            variant="h6"
            flexWrap="wrap"
            className={classes.titleDetails}
          >
            <span className={classes.label}> Start Date</span>
            <span className={classes.value}>
              {props.propsResourcePlanDetail.startDate !== ""
                ? props.propsResourcePlanDetail.startDate.slice(0, 10)
                : "None"}
            </span>
          </Typography>
        </Grid>
        <Grid size={{lg:3, md:3, xs:12, sm:12}}>
          <Typography
            variant="h6"
            flexWrap="wrap"
            className={classes.titleDetails}
          >
            <span className={classes.label}> End Date</span>
            <span className={classes.value}>
              {props.propsResourcePlanDetail.endDate !== ""
                ? props.propsResourcePlanDetail.endDate.slice(0, 10)
                : "None"}
            </span>
          </Typography>
        </Grid>
        <Grid size={{lg:3, md:3}}>
          <Typography
            variant="h6"
            flexWrap="wrap"
            className={classes.titleDetails}
          >
            <span className={classes.label}> Requested Skills</span>
            <span className={classes.value}>
              {props.propsResourcePlanDetail.mandatorySkills !== ""
                ? props.propsResourcePlanDetail.mandatorySkills
                : "None"}
            </span>
          </Typography>
        </Grid>
        <Grid size={{lg:3, md:3}}>
          <Typography
            variant="h6"
            flexWrap="wrap"
            className={classes.titleDetails}
          >
            <span className={classes.label}> Portfolio</span>
            <span className={classes.value}>
              {props.propsResourcePlanDetail.portfolio !== ""
                ? props.propsResourcePlanDetail.portfolio
                : "None"}
            </span>
          </Typography>
        </Grid>

        <Grid size={{lg:3, md:3}}>
          <Typography
            variant="h6"
            flexWrap="wrap"
            className={classes.titleDetails}
          >
            <span className={classes.label}>Assigned To</span>
            <span className={classes.value}>
              {props.propsResourcePlanDetail.assignedTo !== ""
                ? props.propsResourcePlanDetail.assignedTo
                : "Not Assigned"}
            </span>
          </Typography>
        </Grid>
        <Grid size={{lg:3, md:3}}>
          <Typography
            variant="h6"
            flexWrap="wrap"
            className={classes.titleDetails}
          >
            <span className={classes.label}> Resource Plan Link</span>
            <a
              href={props.propsResourcePlanDetail.serviceNowURL}
              className={classes.value}
            >
              {props.propsResourcePlanDetail.rplnNumber}
            </a>
          </Typography>
        </Grid>
        <Grid size={{lg:3, md:3}}>
          <Typography
            variant="h6"
            flexWrap="wrap"
            className={classes.titleDetails}
          >
            <span className={classes.label}> Resource Group</span>
            <span className={classes.value}>
              {props.propsResourcePlanDetail.resourceGroup !== ""
                ? props.propsResourcePlanDetail.resourceGroup
                : "None"}
            </span>
          </Typography>
        </Grid>
        <Grid size={{lg:3, md:3}}>
          <Typography
            variant="h6"
            flexWrap="wrap"
            className={classes.titleDetails}
          >
            <span className={classes.label}> Resource Plan Priority</span>
            <span className={classes.value}>
              {props.propsResourcePlanDetail.priority !== ""
                ? props.propsResourcePlanDetail.priority
                : "None"}
            </span>
          </Typography>
        </Grid>
        <Grid size={{lg:3, md:3}}>
          <Typography
            variant="h6"
            flexWrap="wrap"
            className={classes.titleDetails}
          >
            <span className={classes.label}> Application</span>
            <span className={classes.value}>
              {props.propsResourcePlanDetail.impactApplications !== ""
                ? props.propsResourcePlanDetail.impactApplications
                : "None"}
            </span>
          </Typography>
        </Grid>
        <Grid size={{lg:3, md:3}}>
          <Typography
            variant="h6"
            flexWrap="wrap"
            className={classes.titleDetails}
          >
            <span className={classes.label}> IT Manager</span>
            <span className={classes.value}>
              {props.propsResourcePlanDetail.itManager !== ""
                ? props.propsResourcePlanDetail.itManager
                : "None"}
            </span>
          </Typography>
        </Grid>
        <Grid size={{lg:12, md:12}}>
          <Typography
            variant="h6"
            flexWrap="wrap"
            className={classes.titleDetails}
          >
            <span className={classes.label}> Job Description</span>
            <span className={classes.valueJustify}>
              {props.propsResourcePlanDetail.jobDescription !== ""
                ? props.readMore
                  ? props.propsResourcePlanDetail.jobDescription
                  : props.propsResourcePlanDetail.jobDescription.slice(0, 235)
                : "None"}
              {props.propsResourcePlanDetail.jobDescription !== "" &&
              props.propsResourcePlanDetail.jobDescription.length > 235 ? (
                props.readMore ? (
                  <span
                    onClick={props.toggleReadMore}
                    className={classes.readMore}
                    aria-label="show-less"
                  >
                    ...Show Less
                  </span>
                ) : (
                  <span
                    onClick={props.toggleReadMore}
                    className={classes.readMore}
                    aria-label="read-more"
                  >
                    ...Read More
                  </span>
                )
              ) : null}
            </span>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
