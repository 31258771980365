import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { makeStyles } from "@mui/styles";

import useCustomSearchParams from "../../../hooks/useCustomSearchParams";
import { Tooltip } from "@mui/material";
import { Close } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  displaySkillsContainer: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "20px",
    marginLeft: "10%",
    width: "80%",
    border: "0.08em solid rgba(127, 127, 127, 0.8)",
    borderRadius: "5px",
    overflow: "scroll",
  },
  head: {
    fontSize: "1.1em",
    fontWeight: "500",
    color: "#003C88",
    marginLeft: "25%",
    height: "1em",
  },
  skillContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    alignItems: "center",
    padding: "1rem",
    gap: "0.5em",
  },
  skillsSpan: {
    fontSize: "0.8rem",
    backgroundColor: "rgba(0, 60, 136, 0.1)",
    padding:"0.5em",
    listStyle: "none",
    marginLeft: "2em",
    paddingBottom: "0.2em",
    borderRadius: "0.5em",

  },
  Any: {
    border: "2px solid gold",
    height: "auto",
    width: "auto",
  },
  cross: {
    border: "none",
    padding: "0.2em",
    marginLeft: "0.5em",
    cursor: "pointer",
    background:"none",
    color: "red",
    
  },
}));

export default function DisplaySkills() {
  const classes = useStyles();
  const { listSkills, deleteSkill } = useCustomSearchParams();

  const handleDelete = (skill) => deleteSkill(skill);

  return (
    <div className={classes.displaySkillsContainer}>
      <h1 className={"col-12 " + classes.head}>
        You have entered {Object.keys(listSkills).length} skills & their
        proficiency is mentioned below.
      </h1>
      <br></br>
      <div className="row" style={{ marginTop: "0px" }}>
        <div className={classes.skillContainer}>
          {listSkills.map((skill, index) => (
            
            <Tooltip placement="top" title={skill.skill} key={`${skill}_${index}`}>
            <li className={classes.skillsSpan} key={skill + index}>
              <b>{skill.proficiency}</b>: {skill.skill.slice(0,20)} 
              {skill.skill.length>20?"...":""}
              <button
                className={classes.cross}
                onClick={() => handleDelete(skill.skill)}
              >
                <Close />
              </button>
            </li>
            </Tooltip>
          ))}
        </div>
      </div>
    </div>
  );
}
