import React from "react";

import makeStyles from '@mui/styles/makeStyles';
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {Grid2 as Grid} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  titleDetials: {
    padding: "10px 15px",
  },
  resultErrorDetailsRedeployment: {
    height: 330,
    width: "98%",
    background: "#ffffff",
    margin: "20px 0px 0px 25px",
    borderRadius: 25,
    fontSize: 15,
    outline: "3px solid #abb3e2",
    borderLeft: "5px solid #3f51b5",
  },

  label: {
    flexGrow: 1,
    padding: 20,
    width: "100%",
    fontSize: 15,
    fontStyle: "italic",
  },
  labelAlign: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    color: "lightslategrey",
  },
}));

export default function SearchErrorRedeployment(props) {
  const classes = useStyles();
  return (
    <div>
      <Paper elevation={0} className={classes.resultErrorDetailsRedeployment}>
        <Grid item lg={12} md={12} className={classes.labelAlign}>
          <Typography variant="h6" className={classes.label}>
            {props.message}
          </Typography>
        </Grid>
      </Paper>
    </div>
  );
}
