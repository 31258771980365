import React from "react";
import { makeStyles } from "@mui/styles";

import Shell from "../../../asset/shell-logo.svg";
import Logo from "../../../asset/logo.png";

import { Avatar, Badge } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { LOGIN, LOGOUT } from "../../../ApplicationRoutes/RoutesPath";
import { useAuthentication } from "@sede-x/auth";
import { GetKeyValueCookie } from "../../../Pages/AuthCallBack/Authenticationhelper";


const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
      
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    backgroundColor: "#EAEAEA",
    justifyContent: "space-between",
    alignItems: "center",
    height: "6rem",
    position: "sticky",
    marginBottom: "auto",
    opacity: 0.85,
  },
  logo: {
    height: "3rem",
    margin: "1em",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "space-between" /* Adjust this as needed */,
  },
  loginDetailContainer: {
    display: "flex",
    justifyContent: "space-between" /* Adjust this as needed */,
    margin: "1em",
    fontSize: "0.8rem",
  },
  avatar:{
    backgroundColor: "#FBCE07",
    color:"DD1D21",
  },
  logoutLink:{
    color:"#003C88",
    cursor:"pointer",
    fontSize:"0.9rem",
    padding:"0.2rem",
  },
  hrefLink:{
    textDecoration:"none",
    color:"#003C88",
    marginTop:"0.5rem",
    padding:"0.2rem",
  },
}));

export default function Header() {
  const classes = useStyles();
  const {isLoggedIn} = useAuthentication();

  let loggedInData = GetKeyValueCookie("APP_AUTH_DATA");
  let isUserLoggedIn = localStorage.getItem("LoggedIn");
  let userName = "";
  let userMail = "";
  
  if(loggedInData === null || isUserLoggedIn === false)
  {
    <Link to={LOGIN}/>
  }
  else
  {
  userName = `${loggedInData["givenName"]} ${loggedInData["sn"]}`;
    userMail = `${loggedInData["mail"]}`;
  }

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.logoContainer}>
          <img src={Shell} className={classes.logo} alt="pecten" />
          <img src={Logo} className={classes.logo} alt="logo" />
        </div>
                <div className={classes.loginDetailContainer}>
                  {isLoggedIn &&<>
                  <div style={{ marginRight:"1.2rem"}}>
                    <StyledBadge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      variant="dot"
                    >
                      <Avatar alt={userName} sx={{ width: 46, height: 46 }} className={classes.avatar}>
                        {userName[0]}{userName[userName.indexOf(" ")+1]}
                      </Avatar>
                    </StyledBadge>
                  </div>
                  <div className={classes.userDetails}>
                    <span>{userName}</span>
                    <br />
                    <span>{userMail}</span>
                    <br/>
                    <span className={classes.logoutLink}><a className={classes.hrefLink} href={LOGOUT}>Logout</a></span>
                  </div>
                  </>
                  }
              </div>
      </div>
    </div>
  );
}
