import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "./RoutesPath";
import Header from "../component/SkillsSearch/Header/Header";
import PropTypes from 'prop-types';


const ProtectedRoute = ({ isLoggedIn, login, isLoading, hasError, children, refresh }) => {
    const navigate = useNavigate();
    let url = window.location.href;

    let pathAfterFirstSlash = url.split('/').slice(3).join('/');
    
    if (!pathAfterFirstSlash.includes("callback")) {
        localStorage.setItem("current_path", pathAfterFirstSlash);
    }
    useEffect(() => {
        if (!isLoggedIn && !isLoading) {
            navigate(LOGIN);
        }

    }, [isLoggedIn, isLoading, navigate]);


    return (
        <>
            <Header />
            {children}
        </>
    )
        ;
}


ProtectedRoute.prototype = {
    refresh: PropTypes.func.isRequired,
}
export default ProtectedRoute;