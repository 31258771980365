import axios from "axios";

const env = process.env && Object.keys(process.env).length > 0 ? process.env : window.env;

export function StoreKeyValueLocalStorage(key, value) {
    localStorage.setItem(key, value);
}

export function GetKeyValueLocalStorage(key) {
    return localStorage.getItem(key);
}

export function StoreKeyValueCookie(key, value) {
    document.cookie = `${key}=${value};`;
}

export function GetKeyValueCookie(key) {
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    let cookieValue = '';
    cookieArray.forEach(cookie => {
        if (cookie.trimStart().includes(key)) {
            cookieValue = cookie.split('=')[1];
        }
    });
    if (cookieValue && cookieValue !== "undefined") {
        try {
            return JSON.parse(cookieValue);
        } catch (e) {
            console.error('Error parsing cookie value:', e);
            return null;
        }
    }
    return null;
}
export function checkForTokenExpiration() {
    
    try {
        let accessTokenExpirationTime = GetKeyValueCookie(`Expiration_Time`);
        let currentDate = Math.floor(new Date().getTime() / 1000);
        if (currentDate > (accessTokenExpirationTime - 120)) {
            return false;
        }
        else {
            return true;
        }
    }
    catch (e) {
        console.log(e);
    }
}
export function refreshToken() {
    let accessTokenExpirationData = GetKeyValueCookie(`Authorization_Result`);
    try {
        let expirationTime = "";
        let refreshToken = accessTokenExpirationData?.refreshToken || accessTokenExpirationData?.refresh_token;
        if (!refreshToken) {
            console.error('Refresh token is missing');
        } 
        else {
            axios.post(env.REACT_APP_TOKEN_URL, {
                'grant_type': 'refresh_token',
                'scope': 'openid',
                'refresh_token': refreshToken
            },
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).then((response) => {
                    expirationTime = Math.floor(response.data.accessTokenExpirationDate/1000) || Math.floor(new Date().getTime() / 1000) + response.data.expires_in;
                    StoreKeyValueCookie(`Authorization_Result`, JSON.stringify(response.data));
                    StoreKeyValueCookie(`Expiration_Time`, expirationTime);
                }).catch((error) => {
                    console.log(error);
                })
        }
    }
    catch (e) {
        console.log(e);
    }
}