
import { Typography, Container } from "@mui/material";

import { makeStyles } from "@mui/styles";

import { HOME } from "../../ApplicationRoutes/RoutesPath";
import DisplayMessage from "../../component/SkillsSearch/DisplayMessage/DisplayMessage";
import Header from "../../component/SkillsSearch/Header/Header";

const useStyles = makeStyles((theme) => ({
    logoutButton: {
        marginTop: "1rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#003C88",
        height: "2.5rem",
        color: "white",
        padding: "0.5rem",
        borderRadius: "0.5rem",
    }
}));

const NotFound = ({ message = "Page Not found" }) => {

    const classes = useStyles();

    return (
        <div>
            <Header />
            <Container maxWidth="md" margin="normal" className="homepage-container">
                <Typography variant="h1" component="h2">
                    404
                </Typography>
                <Typography variant="h4" style={{ textAlign: "center" }} mb={2}>
                    <DisplayMessage message={message} />
                </Typography>
                <button onClick={() => window.location.href = HOME} className={classes.logoutButton}>GO BACK TO HOME</button>
            </Container>
        </div>
    )
}


export default NotFound;