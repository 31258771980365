import { useEffect } from "react";
import PropTypes from 'prop-types';
import Header from "../../component/SkillsSearch/Header/Header";
import { Typography, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LOGIN } from "../../ApplicationRoutes/RoutesPath";
import DisplayMessage from "../../component/SkillsSearch/DisplayMessage/DisplayMessage";

const useStyles = makeStyles((theme) => ({
    logoutButton: {
        marginTop: "1rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#003C88",
        height: "2.5rem",
        color: "white",
        padding: "0.5rem",
        borderRadius: "0.5rem",
    }
}));
const Logout = ({ logout, message = "You have been successfully logged out." }) => {
    const classes = useStyles();
    useEffect(() => {
        logout?.();
        localStorage.removeItem("ResourceRoles");
        localStorage.removeItem("SkillsSearch");
        localStorage.removeItem("ParentResourceGroups");
        localStorage.setItem("isLoggedIn", false);
        localStorage.removeItem("current_path");

    }, [logout]);

    return (
        <div>
            <Header />
            <Container maxWidth="md" margin="normal" className="homepage-container">
                <Typography variant="body3" style={{ textAlign: "center" }} mb={2}>
                    <DisplayMessage message={message} />
                </Typography>
                <button onClick={() => window.location.href = LOGIN} className={classes.logoutButton}>Click here to login again</button>
            </Container>
        </div>
    )
}

Logout.propTypes = {
    logout: PropTypes.func.isRequired,
};

export default Logout;