

export const LOGIN = "/";
export const HOME = "/Home";
export const RPLN = "/RPLN";
export const RPLN_NUMBER = "/RPLN/:urlrplnumber";
export const SKILLS = "/Skills";
export const SKILLS_RESULT = "/Skills/Result";
export const CALLBACK = "/callback";
export const LOGOUT = "/logout";
export const ERROR = "/error";
export const NOT_FOUND = "/*"