import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DisplayMessage from "../../component/SkillsSearch/DisplayMessage/DisplayMessage";
import Search from "../../component/SkillsSearch/Search/Search";
import DisplaySkills from "../../component/SkillsSearch/DisplaySkills/DisplaySkills";

import CapabilityCenter from "../../component/SkillsSearch/CapabilityCenter/CapabilityCenter";
import HubComponent from "../../component/SkillsSearch/Hub/HubComponent";
import RoleComponent from "../../component/SkillsSearch/ResourceRoles/RoleComponent";
import "./SkillsSearch.css";
import useCustomSearchParams from "../../hooks/useCustomSearchParams";
import { SEARCH_PARAMS } from "../../const";
import { CircularProgress, Grid2 as Grid } from "@mui/material";

import { fetchOptions } from "../../services/api";
import { makeStyles } from "@mui/styles";
import { HOME } from "../../ApplicationRoutes/RoutesPath";
import "./SkillsSearch.css";

const useStyles = makeStyles((theme) => ({
  grid_container: {
    '@media (min-width: 420px) and (max-width: 900px)': {
      marginTop: '30vh',
    },
    '@media (max-width: 430px)': {
      marginTop: '37vh',
    },
  },
  msg_container: {
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5em",
    marginBottom: "1.5rem",
    height: "2.3em",
    marginTop: "1rem",
  },
  ReviewButton: {
    backgroundColor: "#ef9f37",
    color: "white",
    border: "#ef9f37",
    marginTop: "1em",
    marginRight: "1em",
    borderRadius: "0.5em",
    padding: "0.4em",
    marginBottom:"2em",
  }
}));

export default function SkillsSearch() {

  const navigate = useNavigate();
  const {
    validatedSkills,
    lob,
    hub,
    role,
    capCenter,
    listSkills,
    reset,
    fromDate,
    toDate
  } = useCustomSearchParams();

  const [loadedData, setLoadedData] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    const skills = localStorage.getItem("Skills");
    const parentResourceGroups = localStorage.getItem("ParentResourceGroups");

    if (skills && parentResourceGroups) {
      setLoadedData(true);
    } else {
      fetchOptions().then(() => setLoadedData(true)).catch((err) => console.log(err));
    }
  }, []);
  
  const handleNavigate = () => navigate(HOME);
  const handleGetResources = () => {
    navigate(
      `/Skills/Result?${SEARCH_PARAMS.CAPCENTER}=${encodeURIComponent(
        JSON.stringify(capCenter)
      )}&${SEARCH_PARAMS.FROMDATE}=${fromDate}&${SEARCH_PARAMS.TODATE
      }=${toDate}&${SEARCH_PARAMS.VALIDATEDSKILLS}=${validatedSkills}&${SEARCH_PARAMS.ROLE
      }=${encodeURIComponent(role)}&${SEARCH_PARAMS.HUB}=${encodeURIComponent(
        hub 
      )}&${SEARCH_PARAMS.LOB}=${encodeURIComponent(JSON.stringify(lob))}&${SEARCH_PARAMS.LISTSKILLS
      }=${encodeURIComponent(JSON.stringify(listSkills))}`
    );
  };
  const handleReset = () => reset();

  if (!loadedData) {
    return (
      <div>
        <button className="back_btn" onClick={handleNavigate}>
          <ArrowBackIcon /> Home
        </button>
        <div
          style={{
            marginTop: "1rem",
            width: "100vw",
            height: "50vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="inherit" />
        </div>
      </div>
    );
  }

  return (
    <div>
      <button className="back_btn" onClick={handleNavigate}>
        <ArrowBackIcon /> Home
      </button>
      <Grid container spacing={2} justifyContent={"center"}>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }} justifyContent={"center"}>
          <CapabilityCenter />
        </Grid>
      </Grid>
      <Grid container size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }} className={classes.grid_container} justifyContent={"center"} marginTop={"25vh"}>
        <Grid size={{ xs: 12, sm: 6, md: 5, lg: 5, xl: 5 }}>
          <HubComponent />
        </Grid>
        <Grid size={{ xs: 12, sm: 6, md: 5, lg: 5, xl: 5 }}>
          <RoleComponent />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 9 }} ml={3} alignItems={"center"} className={classes.msg_container}>
          <DisplayMessage
            message={`You have selected ${hub ? hub : "Any"} for Hub and ${role ? role : "Any"} for Resource Role.`}
          />
          <p className="note">
            <span className="span-group-p">Note :</span>Resource Group , Resource
            Roles & Hub are optional. Skill and Proficiency is required.
          </p>
        </Grid>
      </Grid>
      <Search />
      {listSkills.length > 0 && (
        <>
          <DisplaySkills />
          <div className="btn_container">
            <button className={classes.ReviewButton} onClick={handleGetResources} style={{ marginLeft: "25em" }}>
              Get Resources
            </button>
            <button className={classes.ReviewButton} onClick={handleReset}>
              Clear Filters
            </button>
          </div>
        </>
      )}
    </div>
  );
}

