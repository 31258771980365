import { Routes, Route } from 'react-router-dom';
import { HOME, RPLN, RPLN_NUMBER, SKILLS, SKILLS_RESULT, LOGIN, CALLBACK, LOGOUT, NOT_FOUND } from './RoutesPath';

import { useAuthentication } from "@sede-x/auth";

import HomePage from "../Pages/HomePage/HomePage";
import SkillsSearch from "../Pages/SkillsSearchPage/SkillsSearch";
import SkillsResultPage from "../Pages/SkillsResultPage/SkillsResultPage.jsx";
import Login from '../Pages/Login/Login';
import AuthCallback from '../Pages/AuthCallBack/AuthCallback';
import ProtectedRoute from './ProtectedRoutes';
import SearchContainer from '../component/main/SearchContainer.js';
import Logout from '../Pages/Logout/Logout.jsx';
import NotFound from '../Pages/ErrorPage/PageNotFound.jsx';

import { checkForTokenExpiration,  refreshToken, StoreKeyValueCookie, StoreKeyValueLocalStorage } from '../Pages/AuthCallBack/Authenticationhelper.js';

function checkForTokenExpirationEveryMinute() 
{
        setInterval(() => {
                if (!checkForTokenExpiration()) {
                        refreshToken();
                }

        }, 60 * 1000)
};


const ApplicationRoutes = () => {


        const { isLoggedIn, login, isLoading, hasError, decodedToken,refresh,  logout, authorizeResult } = useAuthentication();

        checkForTokenExpirationEveryMinute();

        StoreKeyValueLocalStorage("LoggedIn", isLoggedIn);
        StoreKeyValueLocalStorage("isLoading", isLoading);
        StoreKeyValueLocalStorage("hasError", hasError);

        StoreKeyValueCookie(`APP_AUTH_DATA`,JSON.stringify(decodedToken));
        StoreKeyValueCookie(`Authorization_Result`,JSON.stringify(authorizeResult));
        
        let expTime = Math.floor(new Date(authorizeResult?.accessTokenExpirationDate).getTime()/1000);
        StoreKeyValueCookie(`Expiration_Time`,expTime);

        return (
                <Routes>
                        <Route path={LOGIN} element={<Login login={login} />} />
                        <Route path={CALLBACK}
                                element={<AuthCallback isLoggedIn={isLoggedIn} isLoading={isLoading} hasError={hasError} refresh={refresh} />} />

                        <Route path={HOME} element={
                                <ProtectedRoute isLoading={isLoading} hasError={hasError} isLoggedIn={isLoggedIn} refresh={refresh}>
                                        <HomePage />
                                </ProtectedRoute>
                        } />

                        <Route path={RPLN} element={
                                <ProtectedRoute isLoading={isLoading} hasError={hasError} isLoggedIn={isLoggedIn} refresh={refresh}>
                                        <SearchContainer />
                                </ProtectedRoute>
                        } />

                        <Route path={RPLN_NUMBER} element={
                                <ProtectedRoute isLoading={isLoading} hasError={hasError} isLoggedIn={isLoggedIn} refresh={refresh}>
                                        <SearchContainer />
                                </ProtectedRoute>
                        } />
                        <Route path={SKILLS} element={
                                <ProtectedRoute isLoading={isLoading} hasError={hasError} isLoggedIn={isLoggedIn} refresh={refresh}>
                                        <SkillsSearch />
                                </ProtectedRoute>
                        } />
                        <Route path={SKILLS_RESULT} element={
                                <ProtectedRoute isLoading={isLoading} hasError={hasError} isLoggedIn={isLoggedIn} refresh={refresh}>
                                        <SkillsResultPage />
                                </ProtectedRoute>
                        } />
                        <Route path={LOGOUT} element={<Logout logout={logout} />} />
                        <Route path = {NOT_FOUND} element = {<NotFound/>}/>
                </Routes>
        )

};

export default ApplicationRoutes;