import { useEffect } from "react";
import PropTypes from 'prop-types';

const Login =({login, children})=>{
    useEffect(() => {
        login?.();
        
    }, [login]);
    return children;
}

Login.propTypes = {
    login: PropTypes.func.isRequired,
};  

export default Login;