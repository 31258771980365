import { Typography, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DisplayMessage from "../../component/SkillsSearch/DisplayMessage/DisplayMessage";
import Header from "../../component/SkillsSearch/Header/Header";

const useStyles = makeStyles((theme) => ({
    logoutButton: {
        marginTop: "1rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#003C88",
        height: "2.5rem",
        color: "white",
        padding: "0.5rem",
        borderRadius: "0.5rem",
    },
    statusCode: {
        color: "#DD1D21",
        textAlign: "center",
        marginTop: "3rem",
        letterSpacing: "0.05em",
        fontWeight: "900",
        fontSize: "5rem",
        [theme.breakpoints.down("md")]: {
            fontSize: "4rem",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "3rem",
        },
    },
}));
const ErrorPage = ({ statusCode, statusMessage }) => {

    const classes = useStyles();

    return (
        <div>
            <Header />
            <Container maxWidth="md" margin="normal" className="homepage-container">
                <Typography variant="h2" component="h2" className={classes.statusCode}>
                    {statusCode}
                </Typography>
                <Typography variant="h5" style={{ textAlign: "center" }} mb={2}>
                    <DisplayMessage message={statusMessage} />
                </Typography>

            </Container>
        </div>
    )
}

export default ErrorPage;